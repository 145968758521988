.cookieConsentToggle {
  width: 40px;
  height: 40px;
  position: fixed;
  will-change: transform;
  padding: 9px;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 50%;
  bottom: 20px;
  left: 20px;
  transition: 200ms;
  opacity: 1;
  z-index: 99980;
  @media screen and (min-width: 812px) {
    & {
        width: 40px;
        height: 40px;
    }
  }
  &:hover {
    color: white;
    background: black;
  }
  * {
    fill: currentColor;
  }
}
.cookieConsentWrapper {
  z-index: 99990;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(14, 29, 53, 0.8) 0% 0% no-repeat padding-box;
  color: white;
  padding: 1rem;
  transition: 200ms;
}

.cookieConsent {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.cookieConsent__Content {
  margin-right: 40px;
}

.cookieConsent__Title {
  font: normal normal 300 1rem/2rem Open Sans;
  margin: 0;
  font-weight: bold;
}

.cookieConsent__Description {
  font: normal normal 300 1rem/2rem Open Sans;
  a {
    color: white;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.cookieConsent__Right {
  display: flex;
  align-items: flex-end;
  /* cookie settings button */
  [type=button]:first-child {
    display: none;
  }
}

.cookieConsentOperations {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  transition: 300ms;
  will-change: transform;
  z-index: 99999;
  .cookieConsentOperations__List {
    transform: scale(1);
  }
}

.cookieConsentOperations__List {
  background: white;
  color: black;
  max-width: 500px;
  padding: 1.5rem;
  margin: auto;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 100vh;
  transition: 200ms transform;
  will-change: transform;
  transform: scale(0.95);
}

.cookieConsentOperations__Item {
  display: block;
  padding-left: 60px;
  margin-bottom: 20px;
  &.disabled {
    color: #999;
    label {
      &::after {
        opacity: 0.3;
      }
    }
  }
  input {
    display: none;
  }
  label {
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    display: block;
    position: relative;
    &::before {
      content: '';
      display: block;
      left: -60px;
      background: #dedede;
      height: 20px;
      border-radius: 20px;
      width: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: black;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -58px;
      transition: 200ms;
    }
  }
}

.cookieConsentOperations__Item input:checked + label::after {
  transform: translate(20px, -50%);
}

.cookieConsent__Button {
  padding: 0.5rem 1rem;
  display: block;
  background: white;
  white-space: nowrap;
  border: 0;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  transition: 200ms;
  border-radius: 5px;
}

.cookieConsent__Button--Close {
  background: black;
  color: white;
  margin: 40px 0 0 60px;
  padding: 0.5rem 1rem;
}

.cookieConsent__Button:hover {
  opacity: 0.6;
}

@media only screen and (max-width: 900px) {
  .cookieConsent__Title {
    font: normal normal 700 1.5rem/2rem Open Sans
  }
  .cookieConsent__Description {
    font: normal normal 300 1.5rem/2rem Open Sans
  }
    
  .cookieConsent {
    display: block;
  }
  .cookieConsent__Right {
    margin-top: 20px;
  }
  .cookieConsent__Button {
    margin: 0 10px 10px 0;
  }
  .cookieConsent__Button--Close {
    margin: 40px 0 0;
  }
}
